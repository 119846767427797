<template>
  <div>
    <v-data-table
      v-if="pedidos.length > 0"
      :headers="headers"
      :items="pedidosEstruturado"
      class="border rounded-0"
      :disable-sort="true"
      :items-per-page="-1"
      hide-default-footer
      show-expand
      :expanded.sync="expanded"
      item-key="public_id"
      dense
    >
      <template v-slot:item.cadencia_livre="{ item }">
        {{ item.cadencia_livre ? 'SIM' : 'NÃO' }}
      </template>
      <template v-slot:item.status="{ item }">
        <relatorio-pedido-geral-cotas
          :headersSub="headersSub"
          :item="item"
          :details="details"
        />
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="ma-0 py-0 pr-0">
          <relatorio-pedido-geral-agendamento :pedido="item" />
        </td>
      </template>
    </v-data-table>
    <div v-else>
      <v-alert dense outlined type="info" class="ma-0 rounded-0">
        Pedido nº <strong>{{ pedido.numero_pedido }}</strong> não teve
        distribuições!
      </v-alert>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { BasicPermissions, Sequences } from '@/utils/permissions'
import { contabilizadores } from '@/mixins/pedidos.js'
import RelatorioPedidoGeralCotas from './RelatorioPedidoGeralCotas.vue'
import RelatorioPedidoGeralAgendamento from './RelatorioPedidoGeralAgendamento.vue'

export default {
  name: 'RelatorioPedidoGeralFilho',
  components: { RelatorioPedidoGeralCotas, RelatorioPedidoGeralAgendamento },
  mixins: [contabilizadores],
  props: {
    pedido: {
      type: Object,
      required: true,
    },
    details: {
      type: Boolean,
      required: false,
      default: () => {
        false
      },
    },
    headersSub: {
      type: Array,
      required: false,
      default: () => {
        return [
          { text: 'Status', value: 'descricao' },
          { text: 'Quantidade', value: 'quantidade' },
        ]
      },
    },
  },
  data() {
    return {
      expanded: [],
      pedidos: [],
      headers: [
        { text: 'Número Pedido', value: 'numero_pedido' },
        { text: 'Transportadora', value: 'transportadora.trade_name' },
        {
          text: 'Terminal',
          value: 'terminal.trade_name',
        },
        { text: 'Cadência livre', value: 'cadencia_livre' },
        { text: 'Cotas', value: 'status' },
        { text: '', value: 'data-table-expand' },
      ],
      pedidosEstruturado: [],
    }
  },

  computed: {
    ...mapState('auth', ['empresaAtual']),
    permission() {
      return Sequences.RelatorioPedidos.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },

  async created() {
    this.pedidos = this.pedido.pedidos
    this.atualizarEstrutura()
  },

  methods: {
    ...mapActions('template', ['errorMessage', 'successMessage']),
    ...mapActions('patio', ['getPedidosRelatorio']),

    getLabelEmpresa(item) {
      return `${item.business_name} ${item.cnpj}`
    },

    close() {
      this.$emit('close')
    },
    async atualizarEstrutura() {
      this.pedidosEstruturado = []
      this.estruturaPedidos()
    },
    async estruturaPedidos() {
      let estrutura = []
      this.pedidos.forEach(async (item) => {
        let quantidade_distribuida = this.quantidade_distribuida(item)
        let quantidade_atendida = this.quantidade_atendida(item)
        item.status = [
          {
            descricao: 'Cotas Liberadas',
            quantidade: item.cadencia_livre
              ? quantidade_atendida
              : item.quantidade,
          },
          {
            descricao: 'Cotas Distribuídas',
            quantidade: item.cadencia_livre
              ? quantidade_atendida
              : quantidade_distribuida,
          },
          {
            descricao: 'Atendidas',
            quantidade: quantidade_atendida,
          },
          {
            descricao: 'Disponível',
            quantidade: item.quantidade_restante,
          },
        ]
        await estrutura.push(item)
      })
      this.pedidosEstruturado = [...estrutura]
    },
  },
}
</script>
<style>
.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-container',[_c('v-data-table',{staticClass:"border",attrs:{"headers":_vm.headers.filter(function (item) { return !_vm.is_transportadora ? !item.transportadora : true; }
        ),"items":_vm.pedidosEstruturado,"disable-sort":true,"show-expand":"","expanded":_vm.expanded,"item-key":"public_id","dense":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Relatório")]),_c('v-divider',{staticClass:"mx-5",attrs:{"inset":"","vertical":""}}),_c('v-icon',{attrs:{"title":"Atualizar","medium":""},on:{"click":_vm.atualizarEstrutura}},[_vm._v("mdi-refresh")]),_c('v-divider',{staticClass:"mx-5",attrs:{"inset":"","vertical":""}}),_c('v-switch',{staticClass:"mt-5",attrs:{"label":"Detalhes"},model:{value:(_vm.details),callback:function ($$v) {_vm.details=$$v},expression:"details"}})],1)]},proxy:true},{key:"item.data",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date2br")(item.data))+" ")]}},{key:"item.cadencia_livre",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.cadencia_livre ? 'SIM' : 'NÃO')+" ")]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('relatorio-pedido-geral-cotas',{attrs:{"headersSub":_vm.headersSub,"item":item,"details":_vm.details}})]}},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{staticClass:"ma-0 py-0 pr-0",attrs:{"colspan":headers.length}},[(!_vm.is_transportadora)?_c('relatorio-pedido-geral-filho',{attrs:{"pedido":item,"details":_vm.details}}):_vm._e(),_c('relatorio-pedido-geral-agendamento',{attrs:{"pedido":item}})],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-data-table
    :headers="headersSub"
    :items="item.status"
    :disable-sort="true"
    class="border rounded-0"
    hide-default-footer
    dense
  >
    <template v-slot:body="props"
      ><tbody>
        <tr
          v-for="(item, index) in props.items"
          :key="item.descricao"
          v-show="index == 0 || details"
        >
          <td
            class="d-block d-sm-table-cell"
            v-for="field in headersSub.slice(0, 1)"
            :key="field.value"
          >
            {{ item[field.value] }}
          </td>
          <td
            class="d-block d-sm-table-cell"
            v-for="field in headersSub.slice(1)"
            :key="field.value"
          >
            <span>
              {{ item[field.value] || '0' }}
            </span>
          </td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
</template>
<script>
export default {
  name: 'RelatorioPedidoGeralCotas',
  props: {
    headersSub: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    details: {
      type: Boolean,
      required: false,
      default: () => {
        false
      },
    },
  },
  data() {
    return {}
  },

  computed: {},

  async created() {},

  methods: {},
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.pedidos.length > 0)?_c('v-data-table',{staticClass:"border rounded-0",attrs:{"headers":_vm.headers,"items":_vm.pedidosEstruturado,"disable-sort":true,"items-per-page":-1,"hide-default-footer":"","show-expand":"","expanded":_vm.expanded,"item-key":"public_id","dense":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.cadencia_livre",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cadencia_livre ? 'SIM' : 'NÃO')+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('relatorio-pedido-geral-cotas',{attrs:{"headersSub":_vm.headersSub,"item":item,"details":_vm.details}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"ma-0 py-0 pr-0",attrs:{"colspan":headers.length}},[_c('relatorio-pedido-geral-agendamento',{attrs:{"pedido":item}})],1)]}}],null,false,3987786728)}):_c('div',[_c('v-alert',{staticClass:"ma-0 rounded-0",attrs:{"dense":"","outlined":"","type":"info"}},[_vm._v(" Pedido nº "),_c('strong',[_vm._v(_vm._s(_vm.pedido.numero_pedido))]),_vm._v(" não teve distribuições! ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
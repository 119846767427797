import { mapActions, mapMutations } from 'vuex'
import { cnpj, cpf } from 'cpf-cnpj-validator'

export const methods = {
  ...mapActions('fornecedores', ['getFornecedores']),
  ...mapActions('clientes', ['getClientes']),
  ...mapActions('transportadoras', ['getTransportadoras']),
  ...mapActions('contratos', ['getContratos']),
  ...mapActions('patio', ['getRegioes', 'getRepresentantes']),
  ...mapActions('motoristas', ['getMotoristas']),
  ...mapMutations('relatorioPedidos', ['UPDATE_PARAMS']),

  handleFixParams(fixedParams, [key, param]) {
    if (param.value) {
      const paramsWithCNPJ = [
        'fornecedor_cnpj',
        'terminal_cnpj',
        'cliente',
        'transportadora',
      ]
      const paramsDates = ['data_inicio', 'data_fim']

      const paramsWithCPF = ['representante', 'motorista']
      if (paramsWithCNPJ.includes(key))
        fixedParams[key] = cnpj.strip(param.value)
      else if (paramsWithCPF.includes(key))
        fixedParams[key] = cpf.strip(param.value)
      else if (paramsDates.includes(key)) {
        const date = param.value.split('/')
        fixedParams[key] = `${date[2]}-${date[1]}-${date[0]}`
      } else fixedParams[key] = param.value
    }

    return fixedParams
  },
  fixParams() {
    return Object.entries(this.params).reduce(
      this.handleFixParams,
      new Object()
    )
  },

  search() {
    if (!this.areSettingsEmpty) {
      const fixedParams = { ...this.fixParams() }
      this.UPDATE_PARAMS(fixedParams)
      this.$emit('search')
    }
  },

  async showOnlySelectedOptionsAndGetItems() {
    this.selectedOptions.forEach((option) => {
      this.params[option].visible = true
    })
    this.showDaddy()
  },

  showDaddy() {
    for (let [daddy, dependents] of Object.entries(this.dependents)) {
      const isSomeDependentVisible = dependents.some(
        (dependent) => this.params[dependent].visible
      )
      if (isSomeDependentVisible) {
        this.params[daddy].visible = true
      }
    }
  },
}

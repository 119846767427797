<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container>
      <v-data-table
        :headers="
          headers.filter((item) =>
            !is_transportadora ? !item.transportadora : true
          )
        "
        :items="pedidosEstruturado"
        class="border"
        :disable-sort="true"
        show-expand
        :expanded.sync="expanded"
        item-key="public_id"
        dense
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Relatório</v-toolbar-title>
            <v-divider class="mx-5" inset vertical></v-divider>
            <v-icon title="Atualizar" medium @click="atualizarEstrutura"
              >mdi-refresh</v-icon
            >
            <v-divider class="mx-5" inset vertical></v-divider>
            <v-switch
              v-model="details"
              label="Detalhes"
              class="mt-5"
            ></v-switch>
          </v-toolbar>
        </template>
        <template v-slot:[`item.data`]="{ item }">
          {{ item.data | date2br }}
        </template>
        <template v-slot:item.cadencia_livre="{ item }">
          {{ item.cadencia_livre ? 'SIM' : 'NÃO' }}
        </template>
        <template v-slot:item.status="{ item }">
          <relatorio-pedido-geral-cotas
            :headersSub="headersSub"
            :item="item"
            :details="details"
          />
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="ma-0 py-0 pr-0">
            <relatorio-pedido-geral-filho
              v-if="!is_transportadora"
              :pedido="item"
              :details="details"
            />
            <relatorio-pedido-geral-agendamento :pedido="item" />
          </td>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { BasicPermissions, Sequences } from '@/utils/permissions'
import Perfil from '@/utils/perfil'
import { contabilizadores } from '@/mixins/pedidos.js'
import RelatorioPedidoGeralFilho from './RelatorioPedidoGeralFilho.vue'
import RelatorioPedidoGeralCotas from './RelatorioPedidoGeralCotas.vue'
import RelatorioPedidoGeralAgendamento from './RelatorioPedidoGeralAgendamento.vue'

export default {
  name: 'RelatorioPedidoGeral',
  filters: {
    date2br(data) {
      if (!data) return '-'
      const data_splitted = data.split('-')
      return `${data_splitted[2]}/${data_splitted[1]}/${data_splitted[0]}`
    },
  },
  components: {
    RelatorioPedidoGeralFilho,
    RelatorioPedidoGeralCotas,
    RelatorioPedidoGeralAgendamento,
  },
  mixins: [contabilizadores],
  data() {
    return {
      overlay: false,
      details: false,
      expanded: [],
      headers: [
        { text: 'Data', value: 'data' },
        { text: 'Número Pedido Importação', value: 'numero_pedido_importacao' },
        { text: 'Número Pedido', value: 'numero_pedido' },
        { text: 'Fornecedor', value: 'fornecedor.trade_name' },
        { text: 'Cliente', value: 'cliente.trade_name' },
        { text: 'Tipo frete', value: 'tipoFrete.descricao' },
        { text: 'Produto', value: 'produto.descricao' },
        {
          text: 'Terminal',
          value: 'terminal.trade_name',
        },
        {
          text: 'Cadência livre',
          value: 'cadencia_livre',
          transportadora: true,
        },
        { text: 'Cotas', value: 'status' },
        { text: '', value: 'data-table-expand' },
      ],
      headersSub: [
        { text: 'Status', value: 'descricao' },
        { text: 'Quantidade', value: 'quantidade' },
      ],
      pedidosEstruturado: [],
    }
  },

  computed: {
    ...mapState('auth', ['empresaAtual', 'perfilAtual']),
    ...mapState('patio', ['pedidos']),
    ...mapState('relatorioPedidos', ['params']),
    permission() {
      return Sequences.RelatorioPedidos.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
    is_transportadora() {
      return this.perfilAtual == Perfil.Transportadora
    },
  },

  async created() {},

  methods: {
    ...mapActions('template', ['errorMessage', 'successMessage']),
    ...mapActions('patio', ['getPedidosRelatorio']),

    getLabelEmpresa(item) {
      return `${item.business_name} ${item.cnpj}`
    },

    close() {
      this.$emit('close')
    },
    async atualizarEstrutura() {
      this.overlay = true
      try {
        this.programacoesUnidadeEstruturada = []
        await this.atualizarListaPedidos()
        this.estruturaPedidos()
      } catch (error) {
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      } finally {
        this.overlay = false
      }
    },
    async atualizarListaPedidos(params = null) {
      try {
        if (params == null) {
          params = { ...this.params }
        }
        let filtros = {
          ...params,
          proprietario: this.empresaAtual.public_id,
          pai: !this.is_transportadora,
          valido: this.valido,
        }
        // let filtros = this.pesquisa(params)
        if (!filtros.data_inicio) {
          filtros.data_inicio = new Date(
            new Date(new Date().setHours(0, 0, 0, 0)).setDate(
              new Date().getDate() - new Date().getDay()
            )
          )
            .toISOString()
            .split('T')[0]
        }
        await this.getPedidosRelatorio(filtros)
      } catch (error) {
        if (
          error.response &&
          (error.response.status == 403 || error.response.status == 404)
        ) {
          this.errorMessage('Empresa não possui acesso as programações.')
          return
        }
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      }
    },
    estruturaPedidos() {
      let estrutura = []
      this.pedidos.forEach((item) => {
        let quantidade_distribuida = this.quantidade_distribuida(item)
        let quantidade_atendida = this.quantidade_atendida(item)
        item.status = [
          {
            descricao: 'Cotas Liberadas',
            quantidade: item.cadencia_livre
              ? quantidade_atendida
              : item.quantidade,
          },
          {
            descricao: 'Cotas Distribuídas',
            quantidade: item.cadencia_livre
              ? quantidade_atendida
              : quantidade_distribuida,
          },
          {
            descricao: 'Atendidas',
            quantidade: quantidade_atendida,
          },
          {
            descricao: 'Disponível',
            quantidade: item.quantidade_restante,
          },
        ]
        estrutura.push(item)
      })
      this.pedidosEstruturado = estrutura
    },
  },
}
</script>

<style>
.v-data-table
  > .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>

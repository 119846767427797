<template>
  <div>
    <v-data-table
      v-if="agendamentos.length > 0"
      :headers="headers"
      :items="agendamentos"
      class="border rounded-0"
      :disable-sort="true"
      hide-default-footer
      dense
    >
      <template v-slot:[`item.agendamento.agendado_em`]="{ item }">
        {{
          item.agendamento.agendado_em
            ? new Date(item.agendamento.agendado_em).toLocaleString()
            : '-'
        }}
      </template>
    </v-data-table>
    <div v-else>
      <v-alert dense outlined type="info" class="ma-0 rounded-0">
        Pedido nº <strong>{{ pedido.numero_pedido }}</strong> não teve
        agendamentos!
      </v-alert>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RelatorioPedidoGeralAgendamento',
  props: {
    pedido: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      expanded: [],
      agendamentos: [],
      headers: [
        {
          text: 'Status',
          value: 'agendamento.status',
        },
        {
          text: 'Veículo',
          value: 'agendamento.veiculo.placa_cavalo',
        },
        {
          text: 'Agendado Em',
          value: 'agendamento.agendado_em',
        },
        {
          text: 'Quantidade (T)',
          value: 'quantidade_agendada',
        },
      ],
    }
  },

  computed: {},

  async created() {
    this.agendamentos = this.pedido.pedidos_agendados
  },

  methods: {},
}
</script>

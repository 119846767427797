const contabilizadores = {
  methods: {
    quantidade_distribuida(item) {
      const pedidos = item.pedidos || []
      if (pedidos.length == 0) {
        return 0
      }
      if (pedidos.length == 1) {
        return pedidos[0].quantidade
      }
      let quantidade = pedidos.reduce((accumulator, currentValue) => {
        return (
          (accumulator.quantidade * 1 || accumulator * 1 || 0) * 1 +
          currentValue.quantidade * 1
        )
      })
      return quantidade
    },
    quantidade_atendida_filhos(item) {
      const pedidos_filhos = item.pedidos || []
      if (pedidos_filhos.length == 0) {
        return 0
      }
      if (pedidos_filhos.length == 1) {
        return this.quantidade_atendida(pedidos_filhos[0])
      }
      let quantidade = pedidos_filhos.reduce((accumulator, currentValue) => {
        return (
          (accumulator.quantidade_agendada * 1 || accumulator * 1 || 0) * 1 +
          this.quantidade_atendida(currentValue)
        )
      })
      return quantidade
    },
    quantidade_atendida(item) {
      const pedidos_agendados = item.pedidos_agendados || []
      if (pedidos_agendados.length == 0) {
        return 0 + this.quantidade_atendida_filhos(item)
      }
      if (pedidos_agendados.length == 1) {
        return (
          pedidos_agendados[0].quantidade_agendada +
          this.quantidade_atendida_filhos(item)
        )
      }
      let quantidade = pedidos_agendados.reduce((accumulator, currentValue) => {
        return (
          (accumulator.quantidade_agendada * 1 || accumulator * 1 || 0) * 1 +
          currentValue.quantidade_agendada * 1
        )
      })
      return quantidade + this.quantidade_atendida_filhos(item)
    },
  },
}

export { contabilizadores }
